import React from 'react'

import styled from 'styled-components'
import { Flex, Grid } from 'components/Layout'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import { space, COLOR_CONSTANTS } from 'theme'

import iconCreditCard from 'static/images/home/header/credit_card.svg'
import iconCrossCircle from 'static/images/home/header/cross_circle.svg'
import iconStarOctagram from 'static/images/home/header/star_octagram.svg'

const StyledPoweredByAIWrapper = styled(Flex)`
  align-items: center;
  padding: ${space.s} ${space.m};
  background: ${COLOR_CONSTANTS.SALT};
  border-radius: 47px;
  max-width: fit-content;
`

const HEADER_FEATURE_ITEMS = [
  { icon: iconCreditCard, text: 'No credit card required' },
  { icon: iconStarOctagram, text: 'Free trial available' },
  { icon: iconCrossCircle, text: 'Cancel anytime' },
]

const TrialTerms = () => {
  return (
    <Grid
      mt="l"
      gridTemplateColumns={{ mobile: 'repeat(1, auto)', mobileLarge: 'repeat(3, auto)' }}
      gridGap="m"
      justifyContent={{ mobile: 'center', desktop: 'unset' }}
      display={{ mobile: 'none', mobileLarge: 'grid' }}
    >
      {HEADER_FEATURE_ITEMS.map(({ icon, text }) => (
        <Flex key={text} alignItems="center">
          <LocalImage src={icon} />
          <Text ml="s" fontSize="xs" color="secondaryText">
            {text}
          </Text>
        </Flex>
      ))}
    </Grid>
  )
}

export default TrialTerms
