import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, colors, radius, lineHeights } from 'theme'
import { CONTAINER_MAX_WIDTH, ANALYTICS, FREQUENTLY_ASKED_QUESTIONS } from 'consts'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2, H3 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import GetStartedComponent from 'components/GetStartedComponent'
import G2FeaturesComponent from 'components/G2FeaturesComponent'

import networkImageCross from 'static/images/networks/cross.svg'
import networkImageInstagram from 'static/images/networks/instagram.svg'
import networkImageFacebook from 'static/images/networks/facebook.svg'
import networkImageTwitter from 'static/images/networks/twitter.svg'
import networkImageThreads from 'static/images/networks/threads.svg'
import networkImageYoutube from 'static/images/networks/youtube.svg'
import networkImageLinkedin from 'static/images/networks/linkedin.svg'
import networkImageReddit from 'static/images/networks/reddit.svg'
import networkImagePinterest from 'static/images/networks/pinterest.svg'
import networkImageGoogle from 'static/images/networks/google.svg'
import networkImageTikTok from 'static/images/networks/tiktok.svg'

import imageAnalyticsFeatures from 'static/images/analytics/analytics_and_reporting.webp'
import imageCoreFeatures from 'static/images/analytics/core_feature.webp'
import imageCoreReports from 'static/images/analytics/create_and_share.webp'

import reportsImageCustom from 'static/images/analytics/reports/custom.svg'
import reportsImageDelivery from 'static/images/analytics/reports/delivery.svg'
import reportsImageLabel from 'static/images/analytics/reports/label.svg'
import reportsImagePDF from 'static/images/analytics/reports/pdf.svg'
import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'
import PoweredBy from 'components/PoweredByAI'
import TrialTerms from 'components/TrialTerms'
import GridDropdown from '../Faq/GridDropdown'
import CarouselReviewSection from '../Home/components/CarouselReviewSection'
import SelectPlanButton from '../Home/components/SelectPlanButton'
import HeaderImageAnimation from './HeaderImageAnimation'
import RequestDemoButton from '../Home/components/RequestDemoButton'

import Awards from '../../components/Awards'

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const StyledNetworkWrapper = styled(Flex)`
  box-shadow: 0px 2px 4px rgba(39, 67, 106, 0.08), 0px 8px 20px rgba(136, 154, 178, 0.2);
  border-radius: 12px;
  align-items: center;
  justify-content: center;
`

const StyledNetworkItemWrapper = styled(Flex)`
  &:hover {
    box-shadow: 8px 8px 8px rgb(145 158 171 / 10%);
  }
`

const NETWORK_ITEMS = [
  {
    picture_url: networkImageFacebook,
    title: 'Facebook',
    link: '/integrations/facebook',
    description:
      'Examine Facebook Page performance such as: impressions, reach, audience growth and paid and organic content performance. Understand industry averages and directly compare competitor data to your own.',
  },
  {
    picture_url: networkImageInstagram,
    title: 'Instagram',
    link: '/integrations/instagram',
    description:
      'Track Instagram impact and guide content based on sent message results. Measure follower growth, sentiment, engagement, hashtag trends, conversions and paid content performance.',
  },
  {
    picture_url: networkImageTikTok,
    title: 'TikTok',
    link: '/integrations/tiktok',
    description:
      'Tracking performance of your TikTok videos and images. Discover what resonates with your audience to unlock new opportunities and improve your video planning.',
  },
  {
    picture_url: networkImageYoutube,
    title: 'YouTube',
    link: '/integrations/youtube',
    description:
      'Analyze performance to optimize your YouTube content strategy. Discover what resonates with your audience to unlock new opportunities and improve your video planning.',
  },
  {
    picture_url: networkImageTwitter,
    title: 'X (Twitter)',
    link: '/integrations/twitter',
    description:
      'View aggregate post stats by X (Twitter) profile as well as top-performing paid and organic Tweets. Plan and analyze campaigns using comprehensive keyword, trend and hashtag reports.',
  },
  {
    picture_url: networkImageThreads,
    title: 'Threads',
    link: '/integrations/threads',
    description:
      'Understand the performance of your content on Threads such as impressions, reach, engagement, sentiment and easily spot what resonates well with your audience.',
  },
  {
    picture_url: networkImageCross,
    title: 'Cross Network',
    link: '/integrations/',
    description:
      'See the full picture to analyze the health and success of your entire social strategy with cross-network paid and organic reports that seamlessly blend profile and content metrics across multiple profiles and channels.',
  },
  {
    picture_url: networkImageReddit,
    title: 'Reddit',
    link: '/integrations/reddit',
    description:
      'Examine performance of your reddit profile with focus on posts, engagement and followers. Understand and inform your Reddit strategy by evaluating best-in-class Reddit analytics.',
  },
  {
    picture_url: networkImagePinterest,
    title: 'Pinterest',
    link: '/integrations/pinterest',
    description:
      'Utilize URL tracking to measure website traffic from Pinterest. Assess profile-level Pinterest analytics data and identify high-performing Pins.',
  },
  {
    picture_url: networkImageGoogle,
    title: 'Google Business',
    link: '/integrations/google',
    description: 'Track performance of your business locations from impressions and visitors to post engagement.',
  },
  {
    picture_url: networkImageLinkedin,
    title: 'LinkedIn',
    link: '/integrations/linkedin',
    description:
      'Analyze audience growth, demographics, engagement and impressions across LinkedIn Company Pages. Compare paid versus organic performance to inform advertising spend.',
  },
]

const FEATURE_ITEMS = [
  {
    title: 'Social profile performance',
    description:
      'Access a high-level overview of performance across all connected profiles to quickly evaluate social growth.',
  },
  {
    title: 'Sentiment analysis',
    description:
      'Understand sentiment across messages, comments, mentions and reviews to quickly spot trends and improve efficieny.',
  },

  {
    title: 'Review report',
    description:
      'Track and analyze reviews across the sites that matter to your business: Facebook, Google Business, Yelp, TripAdvisor, OpenTable and TrustPilot.',
  },
  {
    title: 'Post performance report',
    description: 'Analyze cross-channel performance at the post level to understand what resonates and why.',
  },
  {
    title: 'Team performance report',
    description:
      'See how quickly and how often individual users reply to social messages and complete assigned tasks to measure team productivity and improve customer care.',
  },
  {
    title: 'Competitor report',
    description:
      'Track competitor performance across social to benchmark against your own and to identify new opportunities in your industry.',
  },
]

const REPORTS_ITEMS = [
  {
    picture_url: reportsImageCustom,
    title: 'Custom reports',
    description: 'Customize every aspect of your reports',
  },
  {
    picture_url: reportsImageLabel,
    title: 'White label',
    description: 'Add your own logo, title, and description for each report',
  },
  {
    picture_url: reportsImagePDF,
    title: 'Export to PDF',
    description: 'Create amazing looking PDF reports that can easily be shared',
  },
  {
    picture_url: reportsImageDelivery,
    title: 'Automate delivery',
    description: 'Schedule reports in advance to be delivered to teams and clients',
  },
]

const Analytics = () => {
  return (
    <App fullHeader>
      <SEO
        title="Social media Reporting and Metrics that matter"
        description="Simple, intuitive, customizable and fully automated reports that quickly tell you what's working — and what isn’t — with your social media efforts."
        path="/social-media-analytics/"
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              mb={{ mobile: 0, desktop: 'l' }}
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
            >
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column">
                  <Flex width="100%" justifyContent="center" flexDirection="column">
                    <PoweredBy />
                    <Flex
                      mt="m"
                      flexDirection={{ mobile: 'row', desktop: 'column' }}
                      flexWrap="wrap"
                      justifyContent="center"
                    >
                      <H1
                        fontSize="xxxl"
                        fontWeight="bold"
                        color={COLOR_CONSTANTS.DENIM}
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                      >
                        Get an in-depth view of your{' '}
                        <H1
                          fontSize="xxxl"
                          fontWeight="bold"
                          color="primary"
                          textAlign={{ mobile: 'center', desktop: 'left' }}
                          as="span"
                        >
                          social performance
                        </H1>
                      </H1>
                    </Flex>
                    <H2
                      mt="m"
                      pt="s"
                      color="secondaryText"
                      fontSize="m"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                      lineHeight={lineHeights.xxl}
                    >
                      Simple, intuitive, customizable and fully automated reports that quickly tell you what's working —
                      and what isn’t — with your social media efforts.
                    </H2>
                    <TrialTerms />
                    <Flex
                      mt="l"
                      alignItems="center"
                      justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                      flexDirection={{ mobile: 'column', mobileLarge: 'row' }}
                    >
                      <SelectPlanButton />
                      <Flex ml={{ mobile: 0, mobileLarge: 'm' }}>
                        <RequestDemoButton />
                      </Flex>
                    </Flex>

                    <Awards />
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                width="100%"
                ml="auto"
                mr={{ mobile: 'auto', desktop: 0 }}
                mt={{ mobile: 'l', desktop: 0 }}
                pl={{ mobile: '0', desktop: '50px' }}
              >
                <HeaderImageAnimation />
              </Flex>
            </Grid>
            <Flex
              mt={{ mobile: 'l', tablet: 'xl' }}
              pt="m"
              flexDirection="column"
              alignItems="center"
              mb={{ mobile: 'l', desktop: 'l' }}
            >
              <G2FeaturesComponent />
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="680px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              One dashboard for your social media{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                Analytics and Reporting
              </H1>
            </H3>
            <Text mt="m" pt="s" color="secondaryText" fontSize="l">
              Track performance and create reports for all of your social networks including Instagram, Facebook, X
              (Twitter), TikTok, Threads, LinkedIn, and Pinterest — all from one intuitive analytics dashboard.
            </Text>
          </Flex>

          <Flex mt="xl" justifyContent={{ mobile: 'center', desktop: 'space-between' }}>
            <LocalImage
              src={imageAnalyticsFeatures}
              maxWidth="1062px"
              width="100%"
              mx="auto"
              alt="One dashboard for your social media Analytics and Reporting"
            />
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            What’s included:{' '}
            <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Core Features
            </H1>
          </H3>
          <Flex mt="xl" justifyContent={{ mobile: 'center', desktop: 'space-between' }}>
            <LocalImage
              src={imageCoreFeatures}
              display={{ mobile: 'none', tablet: 'block' }}
              maxWidth="350px"
              alt="Core Features"
            />
            <Grid gridGap="l" gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', desktop: 'repeat(2, 1fr)' }}>
              {FEATURE_ITEMS.map(({ title, description }) => (
                <Flex flexDirection="column" key={title} maxWidth="350px" width="100%">
                  <Flex alignItems="center">
                    <Box borderRadius={radius.pill} width="8px" height="8px" minWidth="8px" bg={colors.primary} />
                    <Text ml="s" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl">
                      {title}
                    </Text>
                  </Flex>
                  <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                    {description}
                  </Text>
                </Flex>
              ))}
            </Grid>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg="radial-gradient(51.88% 56.86% at 31.54% 45.31%, #0063E3 36.77%, #0250C9 100%)">
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex mt={{ mobile: '0', tablet: 'xl' }} justifyContent={{ mobile: 'center', desktop: 'space-between' }}>
            <Flex flexDirection="column">
              <Flex flexDirection="column" maxWidth={{ mobile: '100%', tablet: '400px' }}>
                <H3 fontSize="3xl" fontWeight="bold" color="white" textAlign={{ mobile: 'center', tablet: 'left' }}>
                  Create and share <br />
                  beautiful reports
                </H3>
                <Text
                  mt="m"
                  pt="s"
                  color={COLOR_CONSTANTS.SALT}
                  fontSize="l"
                  textAlign={{ mobile: 'center', tablet: 'left' }}
                >
                  Build fully customized reports, and easily export them — to share them with your team, manager, and
                  stakeholders.
                </Text>
              </Flex>
              <Grid mt="xl" gridGap="l" gridTemplateColumns={{ mobile: 'repeat(2, 1fr)', tablet: 'repeat(2, 1fr)' }}>
                {REPORTS_ITEMS.map(({ title, description, picture_url }) => (
                  <Flex flexDirection="column" key={title} maxWidth={{ mobile: '100%', tablet: '255px' }} width="100%">
                    <LocalImage src={picture_url} width="60px" height="60px" alt={title} />
                    <Text mt="m" color="white" fontWeight="bold" fontSize="xl" textAlign="left">
                      {title}
                    </Text>
                    <Text mt="s" fontSize="m" color={COLOR_CONSTANTS.SALT} textAlign="left">
                      {description}
                    </Text>
                  </Flex>
                ))}
              </Grid>
            </Flex>
            <LocalImage
              src={imageCoreReports}
              display={{ mobile: 'none', tablet: 'block' }}
              maxWidth={{ tablet: '345px', desktop: '544px' }}
            />
          </Flex>
        </Container>
      </Wrapper>

      <CarouselReviewSection />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="left">
            Analytics{' '}
            <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              by Network
            </H1>
          </H3>

          <Grid mt="xl" gridGap="l" gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', tablet: 'repeat(2, 1fr)' }}>
            {NETWORK_ITEMS.map(({ title, description, picture_url, link }) => (
              <StyledNetworkItemWrapper
                flexDirection="column"
                key={title}
                maxWidth={{ mobile: '100%', tablet: '350px' }}
                width="100%"
                as="a"
                href={link}
              >
                <Flex alignItems="center">
                  <StyledNetworkWrapper width="60px" height="60px">
                    <LocalImage src={picture_url} alt={title} />
                  </StyledNetworkWrapper>
                  <Text ml="m" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl">
                    {title}
                  </Text>
                </Flex>
                <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                  {description}
                </Text>
              </StyledNetworkItemWrapper>
            ))}
          </Grid>
        </Container>
      </Wrapper>

      <GetStartedComponent activeKey={ANALYTICS} />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Frequently Asked{' '}
            <H3 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Questions
            </H3>
          </H3>
          <Flex flexDirection="column" mt="xl" width="100%">
            <GridDropdown faqs={FREQUENTLY_ASKED_QUESTIONS.reports} />
            <Flex mt="l" justifyContent="flex-end">
              <StyledLinkText as="a" href="/faq" textAlign="left">
                <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                  ALL OUR FAQS
                </Text>
                <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
              </StyledLinkText>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Footer />
    </App>
  )
}

export default Analytics
