import React from 'react'
import styled from 'styled-components'
import { COLOR_CONSTANTS } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import Container from 'components/Container'

import imageIntegrations from 'static/images/solutions_enterprise/integrations.webp'
import imageDoubleArrowRightWhite from 'static/images/home/double_arrow_right_white.svg'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const IntegrationsComponent = () => {
  return (
    <Wrapper bg="radial-gradient(51.88% 56.86% at 31.54% 45.31%, #0063E3 36.77%, #0250C9 100%)">
      <Container
        pl={{ mobile: 'l', tablet: 'l' }}
        pr={{ mobile: 'l', tablet: 'l' }}
        py={{ mobile: 'l', tablet: 'xxl' }}
        position="relative"
        maxWidth={CONTAINER_MAX_WIDTH}
        width="100%"
        height="100%"
      >
        <Flex
          justifyContent={{ mobile: 'center', desktop: 'space-between' }}
          flexDirection={{ mobile: 'column', desktop: 'row' }}
          alignItems="center"
        >
          <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 1 }} maxWidth="450px" width="100%">
            <LocalImage
              src={imageIntegrations}
              width="100%"
              alt="Trusted partnerships & integrations across leading platforms"
            />
          </Flex>
          <Flex
            order={{ mobile: 1, desktop: 2 }}
            flexDirection="column"
            alignItems={{ mobile: 'center', desktop: 'flex-start' }}
            pl={{ mobile: '0', desktop: 'xl' }}
          >
            <Text fontWeight="bold" fontSize="4xl" color="white" textAlign={{ mobile: 'center', desktop: 'left' }}>
              Trusted partnerships & integrations across leading platforms
            </Text>

            <Flex mt="m" pt="s" maxWidth={{ mobile: '100%', desktop: '600px' }}>
              <Text color={COLOR_CONSTANTS.SALT} fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
                Vista Social builds and maintains strong network partnership and integrations to help you unify your
                customer touch points and keep pace with changes in the social landscape.
              </Text>
            </Flex>
            <Flex mt="l" pt="m">
              <StyledLinkText as="a" href="/integrations" textAlign="left">
                <Text fontSize="l" fontWeight="medium" textAlign="left" color="white" as="span">
                  SEE ALL VISTA SOCIAL INTEGRATIONS
                </Text>
                <LocalImage src={imageDoubleArrowRightWhite} />
              </StyledLinkText>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Wrapper>
  )
}

IntegrationsComponent.defaultProps = {}

IntegrationsComponent.propTypes = {}

export default IntegrationsComponent
