import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Flex, Box } from 'components/Layout'
import LocalImage from 'components/LocalImage'

import imageHeader from 'static/images/home/header/header_picture.webp'
import imageHeaderFollowers from 'static/images/home/header/followers.webp'
import imageHeaderInstagramPost from 'static/images/home/header/instagram_post.webp'
import imageHeaderInstagram from 'static/images/home/header/instagram.webp'
import imageHeaderTwitter from 'static/images/home/header/twitter.webp'
import imageHeaderViews from 'static/images/home/header/views.webp'
import imageHeaderMessage from 'static/images/home/header/message.webp'
import imageHeaderThreads from 'static/images/home/header/threads.webp'
import imageHeaderReview from 'static/images/home/header/review.webp'

const StyledImageHeader = styled(LocalImage)`
  position: absolute;
`

const StyledImageHeaderFollowers = styled(StyledImageHeader)`
  box-shadow: 0px 9.93413px 11.0379px rgba(14, 28, 56, 0.07);
  ${({ coefficient }) => `  
  bottom: ${coefficient * 80}px;
  left: ${coefficient * 53}px;
  width: ${coefficient * 160}px;
  height: ${coefficient * 134.86}px`};
  animation: home-image-header-followers 8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite both;
  @keyframes home-image-header-followers {
    0% {
      transform: translateY(0) translateX(0);
    }
    16.6666666667% {
      transform: translateY(24px) translateX(-24px);
    }
    33.3333333333% {
      transform: translateY(24px) translateX(-24px);
    }
    50.0000000001% {
      transform: translateY(36px) translateX(-36px);
    }
    66.6666666668% {
      transform: translateY(36px) translateX(-36px);
    }
    83.3333333335% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`

const StyledImageHeaderTwitter = styled(StyledImageHeader)`
  ${({ coefficient }) => `  
  bottom: ${coefficient * 144}px;
  left: ${coefficient * 30}px;
  width: ${coefficient * 29}px;
  height: ${coefficient * 29}px`};
  animation: home-image-header-twitter 8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite both;
  @keyframes home-image-header-twitter {
    0% {
      transform: translateY(0) translateX(0);
    }
    16.6666666667% {
      transform: translateY(24px) translateX(-24px);
    }
    33.3333333333% {
      transform: translateY(24px) translateX(-24px);
    }
    50.0000000001% {
      transform: translateY(36px) translateX(-36px);
    }
    66.6666666668% {
      transform: translateY(36px) translateX(-36px);
    }
    83.3333333335% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`

const StyledImageHeaderInstagramPost = styled(StyledImageHeader)`
  box-shadow: -6.79118px -3.39559px 23.09px rgb(26 51 100 / 6%), 12.2241px 8.82853px 25.8065px rgb(26 51 100 / 10%);
  backdrop-filter: blur(14.9406px);
  ${({ coefficient }) => `  
  left: ${coefficient * -15}px;
  top: ${coefficient * 60}px;
  width: ${coefficient * 175}px;
  height: ${coefficient * 211}px`};
  animation: home-image-header-instagram-post 8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite both;
  @keyframes home-image-header-instagram-post {
    0% {
      transform: translateY(0) translateX(0);
    }
    16.6666666667% {
      transform: scale(1.06) translateY(8px) translateX(16px);
    }
    33.3333333333% {
      transform: scale(1.06) translateY(8px) translateX(16px);
    }
    50.0000000001% {
      transform: scale(1.12) translateY(16px) translateX(16px);
    }
    66.6666666668% {
      transform: scale(1.12) translateY(16px) translateX(16px);
    }
    83.3333333335% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`

const StyledImageHeaderInstagram = styled(StyledImageHeader)`
  ${({ coefficient }) => `  
  left: ${coefficient * 115}px;
  top: ${coefficient * 50}px;
  width: ${coefficient * 58}px; 
  height: ${coefficient * 58}px`};
  animation: home-image-header-instagram 8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite both;
  @keyframes home-image-header-instagram {
    0% {
      transform: translateY(0) translateX(0);
    }
    16.6666666667% {
      transform: scale(1.06) translateY(8px) translateX(16px);
    }
    33.3333333333% {
      transform: scale(1.06) translateY(8px) translateX(16px);
    }
    50.0000000001% {
      transform: scale(1.12) translateY(16px) translateX(16px);
    }
    66.6666666668% {
      transform: scale(1.12) translateY(16px) translateX(16px);
    }
    83.3333333335% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`

const StyledImageHeaderMessage = styled(StyledImageHeader)`
  box-shadow: 0px 9.09039px 10.1004px rgba(14, 28, 56, 0.07);
  ${({ coefficient }) => `  
  right: ${coefficient * 76}px;
  bottom: ${coefficient * 30}px;
  width: ${coefficient * 308.11}px;
  height: ${coefficient * 57.11}px`};

  animation: home-image-header-message 8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite both;
  @keyframes home-image-header-message {
    0% {
      transform: translateY(0) translateX(0);
    }
    16.6666666667% {
      transform: scale(1.12) translateY(0) translateX(-16px);
    }
    33.3333333333% {
      transform: scale(1.12) translateY(0) translateX(-16px);
    }
    50.0000000001% {
      transform: scale(1.12) translateY(0) translateX(-8px);
    }
    66.6666666668% {
      transform: scale(1.12) translateY(0) translateX(-8px);
    }
    83.3333333335% {
      transform: scale(1) translateY(0px) translateX(0px);
    }
    100% {
      transform: scale(1) translateY(0px) translateX(0px);
    }
  }
`

const StyledImageHeaderReview = styled(StyledImageHeader)`
  box-shadow: 0px 5.77659px 6.41844px rgba(14, 28, 56, 0.07);
  ${({ coefficient }) => `  
  right: ${coefficient * 80}px; 
  bottom: ${coefficient * 100}px;
  width: ${coefficient * 204.76}px;
  height: ${coefficient * 112.35}px`};

  animation: home-image-header-review 8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite both;
  @keyframes home-image-header-review {
    0% {
      transform: translateY(0) translateX(0);
    }
    16.6666666667% {
      transform: rotate(-6deg) translateY(-8px);
    }
    33.3333333333% {
      transform: rotate(-6deg) translateY(-8px);
    }
    50.0000000001% {
      transform: rotate(6deg) translateY(-8px);
    }
    66.6666666668% {
      transform: rotate(6deg) translateY(-8px);
    }
    83.3333333335% {
      transform: rotate(0deg) translateY(0px);
    }
    100% {
      transform: rotate(0deg) translateY(0px);
    }
  }
`

const StyledImageHeaderViews = styled(StyledImageHeader)`
  box-shadow: 0px 6.56736px 7.29707px rgba(14, 28, 56, 0.07);
  ${({ coefficient }) => `  
  right: ${coefficient * 115}px;  
  top: ${coefficient * 140}px;
  width: ${coefficient * 114.43}px;
  height: ${coefficient * 65.84}px`};
  animation: home-image-header-views 8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite both;
  @keyframes home-image-header-views {
    0% {
      transform: translateY(0) translateX(0);
    }
    16.6666666667% {
      transform: scale(1.0876);
    }
    33.3333333333% {
      transform: scale(1.0876);
    }
    50.0000000001% {
      transform: scale(1.0876) translateY(8px) translateX(8px);
    }
    66.6666666668% {
      transform: scale(1.0876) translateY(8px) translateX(8px);
    }
    83.3333333335% {
      transform: scale(1) translateY(0px);
    }
    100% {
      transform: scale(1) translateY(0px);
    }
  }
`

const HeaderImageAnimation = () => {
  // this is a coefficient of the image. Real size 500px, but on the site it is 400px, so the coefficient would be 400/500
  const [coefficient, setCoefficient] = useState(1)
  const imageWrapperRef = useRef(null)

  const countCoefficient = () => {
    if (imageWrapperRef && imageWrapperRef.current) {
      const { current: { clientWidth, firstChild: { naturalWidth } = {} } = {} } = imageWrapperRef
      const newCoefficient = (clientWidth * 2) / naturalWidth
      if (!Number.isNaN(newCoefficient) && newCoefficient > 0) {
        setCoefficient(newCoefficient)
      }
    }
  }

  useEffect(() => {
    countCoefficient()
    window.addEventListener('resize', countCoefficient)

    return () => {
      window.removeEventListener('resize', countCoefficient)
    }
  }, [])

  return (
    <Flex width="100%">
      <Flex maxWidth="664px" mx="auto">
        <Box width="100%" position="relative" ref={imageWrapperRef}>
          <LocalImage
            src={imageHeader}
            width="100%"
            alt="The most complete social media management platform for brands and agencies"
            onLoad={() => {
              countCoefficient()
            }}
            loading="eager"
            maxWidth="592px"
            maxHeight="480px"
          />
          <StyledImageHeaderFollowers src={imageHeaderFollowers} coefficient={coefficient} loading="eager" />
          <StyledImageHeaderInstagramPost src={imageHeaderInstagramPost} coefficient={coefficient} loading="eager" />
          <StyledImageHeaderInstagram src={imageHeaderInstagram} coefficient={coefficient} loading="eager" />
          <StyledImageHeaderTwitter src={imageHeaderTwitter} coefficient={coefficient} loading="eager" />
          <StyledImageHeaderMessage src={imageHeaderMessage} coefficient={coefficient} loading="eager" />
          <StyledImageHeaderReview src={imageHeaderReview} coefficient={coefficient} loading="eager" />
          <StyledImageHeaderViews src={imageHeaderViews} coefficient={coefficient} loading="eager" />
        </Box>
      </Flex>
    </Flex>
  )
}

export default HeaderImageAnimation
