import React from 'react'
import styled from 'styled-components'
import { COLOR_CONSTANTS, fontSizes } from 'theme'
import { SCHEDULE_DEMO_LINK } from 'consts'
import { pxToRem } from 'helpers'
import { Flex, Box } from 'components/Layout'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import Container from 'components/Container'
import Button from 'components/Button'

import imageGetStarted from 'static/images/solutions_enterprise/get_started.webp'
import imageGetStartedArrow from 'static/images/solutions_enterprise/get_started_arrow.svg'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const StyledScheduleDemoButton = styled(Button.Gradient)`
  font-size: ${fontSizes.m};
  height: 100%;
  padding-left: ${pxToRem(48)};
  padding-right: ${pxToRem(48)};
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
`

const GetStartedComponent = () => {
  return (
    <Wrapper mt={{ mobile: 'l', tablet: 'xxl' }} bg="linear-gradient(180deg, #FFFFFF 0%, #EAF3FF 92.48%);">
      <Container
        pl={{ mobile: 'l', tablet: 'l' }}
        pr={{ mobile: 'l', tablet: 'l' }}
        pb={{ mobile: 'l', tablet: 'xxl' }}
        position="relative"
        maxWidth="1145px"
        width="100%"
        height="100%"
      >
        <Flex
          justifyContent={{ mobile: 'center', desktop: 'space-between' }}
          flexDirection={{ mobile: 'column', desktop: 'row' }}
          alignItems="center"
        >
          <Flex
            order={{ mobile: 2, desktop: 2 }}
            maxWidth="480px"
            width="100%"
            as="a"
            href={SCHEDULE_DEMO_LINK}
            target="_blank"
            rel="noreferrer noopener nofollow"
            zIndex="2"
          >
            <LocalImage src={imageGetStarted} width="100%" alt="Want to get started?" />
          </Flex>
          <Flex
            order={{ mobile: 1, desktop: 1 }}
            flexDirection="column"
            alignItems={{ mobile: 'center', desktop: 'flex-start' }}
            maxWidth={{ mobile: '100%', desktop: '450px' }}
            position="relative"
          >
            <Text
              fontWeight="bold"
              fontSize="3xl"
              color={COLOR_CONSTANTS.DENIM}
              textAlign={{ mobile: 'center', desktop: 'left' }}
            >
              Ready to{' '}
              <Text as="span" fontWeight="bold" fontSize="3xl" color="primary">
                learn more{' '}
              </Text>
              ?
            </Text>

            <Text mt="m" pt="s" color="secondaryText" fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
              Vista Social is powerful yet very easy to use and setup with a multitude of support options, articles, and
              training videos. We do, offer and recommend that you schedule a call with our team to learn more about how
              to deploy Vista Social for your business.
            </Text>

            <Flex mt="l" pt="m">
              <Flex height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}>
                <StyledScheduleDemoButton
                  as="a"
                  href={SCHEDULE_DEMO_LINK}
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  Schedule a demo
                </StyledScheduleDemoButton>
              </Flex>
            </Flex>

            <Box position="absolute" display={{ mobile: 'none', desktop: 'block' }} right="-170px" top="20px">
              <LocalImage src={imageGetStartedArrow} width="100%" alt="Want to get started?" />
            </Box>
          </Flex>
        </Flex>
      </Container>
    </Wrapper>
  )
}

GetStartedComponent.defaultProps = {}

GetStartedComponent.propTypes = {}

export default GetStartedComponent
