import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { COLOR_CONSTANTS, radius } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { Flex, Grid } from 'components/Layout'
import { Text, H3 } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import Container from 'components/Container'

import featureImageComment from 'static/images/publish/features/comment.svg'
import featureImageFinder from 'static/images/publish/features/finder.svg'
import featureImageIntegrations from 'static/images/publish/features/integrations.svg'
import featureImageLike from 'static/images/publish/features/like.svg'
import featureImageQueue from 'static/images/publish/features/queue.svg'
import featureImageScheduling from 'static/images/publish/features/scheduling.svg'
import featureImagePlanning from 'static/images/publish/features/planning.svg'
import featureImageAudio from 'static/images/publish/features/audio.svg'
import featureImageAI from 'static/images/publish/features/ai.svg'
import featureImageTags from 'static/images/publish/features/tags.svg'
import featureImageThumb from 'static/images/publish/features/thumb.svg'
import featureImageExt from 'static/images/publish/features/ext.svg'
import featureImageZapier from 'static/images/publish/features/zapier.svg'
import featureImageMobile from 'static/images/publish/features/mobile.svg'
import featureImageTimes from 'static/images/publish/features/times.svg'
import featureImageWorkflow from 'static/images/publish/features/workflow.svg'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const FEATURE_ITEMS = [
  {
    picture_url: featureImageScheduling,
    title: 'Scheduling',
    description:
      'Import and schedule multiple social posts across different profiles and networks simultaneously. Save time and aid in planning by queuing up various posts in advance.',
    link: '/social-media-publishing/',
  },
  {
    picture_url: featureImageTimes,
    title: 'Optimal send times',
    description:
      'Utilize optimization algorithms to automatically schedule content to post at times proven to generate engagement from your social audiences.',
  },
  {
    picture_url: featureImageWorkflow,
    title: 'Approval workflow',
    description:
      'Generate workflows to guide the submission, review, and approval of messages to maintain oversight, safeguard brand standards and simplify collaboration.',
  },
  {
    picture_url: featureImageIntegrations,
    title: 'Media integrations',
    description:
      'Utilize built-in media libraries to easily keep your images and videos organized. Easily upload new media. Utilize discover features to find great images, gifs and videos to share.',
  },
  {
    picture_url: featureImageFinder,
    title: 'Content finder',
    description:
      'Find timely and engaging content from social media to supplement original content or find inspiration.',
  },
  {
    picture_url: featureImageLike,
    title: 'First like',
    description:
      'Drop a first like on your post to get more engagement on your post! It also makes your post look that much better!',
  },
  {
    picture_url: featureImageQueue,
    title: 'Post queues',
    description:
      'Set up predefined times of when you would like to post for each profile. This allows for lightning-fast, error-free scheduling.',
  },
  {
    picture_url: featureImageComment,
    title: 'First comment',
    description: 'Kick start your engagement with a first comment. Great place to keep all of your hashtags too!',
  },

  {
    picture_url: featureImagePlanning,
    title: 'Planning',
    description:
      'Plan and schedule your content visually while previewing how your social profile feed will look once the post is published on Instagram and TikTok.',
  },

  {
    picture_url: featureImageAudio,
    title: 'Trending Audio',
    description:
      'Use trending audio in your videos by selecting from hundreds of carefully curated sounds by our team or by uploading your own.',
  },

  {
    picture_url: featureImageAI,
    title: 'AI Assistant',
    description:
      'Generate engaging social posts and replies tailored to your target audience, in your brand voice, in seconds using Vista Social AI Assistant.',
  },
  {
    picture_url: featureImageTags,
    title: 'User and Product tags',
    description: 'Create more engaging content by tagging users and products in your Instagram posts.',
  },
  {
    picture_url: featureImageThumb,
    title: 'Custom thumbnails',
    description: 'Attach custom thumbnails to your videos by selecting specific frames or uploading your own.',
  },
  {
    picture_url: featureImageExt,
    title: 'Browser extension',
    description:
      'Share excellent content as you see it, collect photos, videos, and sounds from anywhere on the web to be used in your posts.',
  },
  {
    picture_url: featureImageMobile,
    title: 'Mobile app',
    description:
      'Seamlessly manage social from one mobile app—access your content calendar, social inbox and reports from anywhere at anytime.',
  },
  {
    picture_url: featureImageZapier,
    title: 'Zapier',
    description:
      'Automate content creation and notification using Zapier and connect your Vista Social account to other apps.',
  },
]

const FeaturesComponent = ({ title }) => {
  return (
    <Wrapper bg={COLOR_CONSTANTS.SALT}>
      <Container
        pl={{ mobile: 'l', tablet: 'l' }}
        pr={{ mobile: 'l', tablet: 'l' }}
        py={{ mobile: 'l', tablet: 'xxl' }}
        position="relative"
        maxWidth={CONTAINER_MAX_WIDTH}
        width="100%"
        height="100%"
      >
        <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
          <H3 as="span" fontSize="3xl" fontWeight="bold" color="primary">
            Top features{' '}
          </H3>
          for {title}
        </H3>
        <Grid
          mt="xl"
          gridGap="m"
          gridTemplateColumns={{
            mobile: 'repeat(1, 1fr)',
            mobileLarge: 'repeat(2, 1fr)',
            tablet: 'repeat(3, 1fr)',
            desktop: 'repeat(4, 1fr)',
          }}
        >
          {FEATURE_ITEMS.map(({ title, description, picture_url }) => {
            return (
              <Flex
                flexDirection="column"
                key={title}
                width="100%"
                bg={COLOR_CONSTANTS.WHITE}
                borderRadius={radius.l}
                py="s"
                px="s"
              >
                <Flex alignItems="center">
                  <LocalImage src={picture_url} width="44px" height="44px" alt={title} />
                  <Text ml="s" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="l">
                    {title}
                  </Text>
                </Flex>
                <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                  {description}
                </Text>
              </Flex>
            )
          })}
        </Grid>
      </Container>
    </Wrapper>
  )
}

FeaturesComponent.defaultProps = {}

FeaturesComponent.propTypes = {
  title: PropTypes.string.isRequired,
}

export default FeaturesComponent
