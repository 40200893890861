import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, space, colors, radius, lineHeights } from 'theme'
import { CONTAINER_MAX_WIDTH, REVIEWS, FREQUENTLY_ASKED_QUESTIONS } from 'consts'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2, H3 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import GetStartedComponent from 'components/GetStartedComponent'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import Link from 'components/Link'

import imageStar from 'static/images/reviews/star.svg'
import imageCoreFeatures from 'static/images/reviews/core_feature.webp'
import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'

import networkImageYelp from 'static/images/networks/yelp.svg'
import networkImageGoogle from 'static/images/networks/google.svg'
import networkImageTrustpilot from 'static/images/networks/trustpilot.svg'
import networkImageFacebook from 'static/images/networks/facebook.svg'
import networkImageOpenTable from 'static/images/networks/opentable.svg'
import networkImageTripAdvisor from 'static/images/networks/tripadvisor.svg'

import featureImageAlert from 'static/images/reviews/features/alert.svg'
import featureImageMonitor from 'static/images/reviews/features/monitor.svg'
import featureImageRespond from 'static/images/reviews/features/respond.svg'
import featureImageReport from 'static/images/reviews/features/report.svg'

import PoweredBy from 'components/PoweredByAI'
import TrialTerms from 'components/TrialTerms'
import CarouselReviewSection from '../Home/components/CarouselReviewSection'
import GridDropdown from '../Faq/GridDropdown'
import QuestionAnswerComponent from '../Faq/QuestionAnswerComponent'
import SelectPlanButton from '../Home/components/SelectPlanButton'
import HeaderImageAnimation from './HeaderImageAnimation'

import Awards from '../../components/Awards'
import RequestDemoButton from '../Home/components/RequestDemoButton'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const StyledFeatureItemWrapper = styled(Flex)`
  background: ${colors.primary};
  padding: ${space.l};
`

const RoundedBox = styled(Box)`
  border-radius: ${radius.pill};
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const NETWORKS = [
  { image: networkImageFacebook, pagePath: '/integrations/facebook' },
  { image: networkImageGoogle, pagePath: '/integrations/google' },
  { image: networkImageOpenTable, pagePath: '/integrations/opentable' },
  { image: networkImageTripAdvisor, pagePath: '/integrations/tripadvisor' },
  { image: networkImageTrustpilot, pagePath: '/integrations/trustpilot' },
  { image: networkImageYelp, pagePath: '/integrations/yelp' },
]

const REVIEW_ITEMS = [
  {
    title: 'Never miss a review',
    description:
      'Bring all of your reviews from Facebook, Google My Business, Yelp, TripAdvisor, OpenTable and TrustPilot into one centralized platform for a more cohesive and efficient strategy.',
  },
  {
    title: 'Prioritize your strategy',
    description:
      'Sort, filter, and create custom views to build a more focused and personalized approach that meets review management needs for your organization.',
  },
  {
    title: 'Save time and effort',
    description:
      'Easily monitor and manage reviews across Facebook, Google My Business, Yelp, TripAdvisor, OpenTable and TrustPilot in a single stream so you can stay organized and informed, and respond quickly to every review.',
  },
  {
    title: 'Identify opportunities',
    description:
      'Get insights about your business operations that help you improve the customer and employee experiences through timely responses to important feedback.',
  },
]

const FEATURE_ITEMS = [
  {
    picture_url: featureImageAlert,
    title: 'Alerts',
    description:
      'Be always in touch! Get timely notifications of new reviews via email, text and in-app notifications.',
  },
  {
    picture_url: featureImageMonitor,
    title: 'Monitor',
    description: 'Track reviews from Facebook, Google Business, Yelp, TripAdvisor, OpenTable and TrustPilot.',
  },
  {
    picture_url: featureImageRespond,
    title: 'Respond',
    description: 'Response to Facebook and Google Business reviews right from your Vista Social dashboard.',
  },
  {
    picture_url: featureImageReport,
    title: 'Reports',
    description: 'Analyze review data, understand trends, spot important reviews and share this reporting.',
  },
]

const Reviews = () => {
  return (
    <App fullHeader>
      <SEO
        title="Strengthen your online reputation with review management"
        description="Empower your business with tools designed for more reviews, a higher star rating, and a winning online reputation."
        path="/reviews/"
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              mb={{ mobile: 0, desktop: 'xl' }}
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
            >
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column">
                  <Flex width="100%" justifyContent="center" flexDirection="column">
                    <PoweredBy />
                    <Flex
                      mt="m"
                      flexDirection={{ mobile: 'row', desktop: 'column' }}
                      flexWrap="wrap"
                      justifyContent="center"
                    >
                      <H1
                        fontSize="xxxl"
                        fontWeight="bold"
                        color={COLOR_CONSTANTS.DENIM}
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                      >
                        Strengthen your online reputation with{' '}
                        <H1
                          fontSize="xxxl"
                          fontWeight="bold"
                          color="primary"
                          textAlign={{ mobile: 'center', desktop: 'left' }}
                          as="span"
                        >
                          review management
                        </H1>
                      </H1>
                    </Flex>
                    <H2
                      mt="m"
                      pt="s"
                      pr={{ mobile: 0, desktop: 'l' }}
                      color="secondaryText"
                      fontSize="m"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                      lineHeight={lineHeights.xxl}
                    >
                      Empower your business with tools designed for more reviews, a higher star rating, and a winning
                      online reputation.
                    </H2>
                    <Flex flexDirection="column" mt="m" alignItems={{ mobile: 'center', desktop: 'flex-start' }}>
                      <Text fontSize="s" color="secondaryText" textAlign="left">
                        Reviews from
                      </Text>
                      <Flex mt="s">
                        {NETWORKS.map(({ image, pagePath }) => (
                          <Link href={pagePath} mr="s" key={pagePath}>
                            <LocalImage
                              src={image}
                              alt="Your posts for the best performance on each social network."
                              width="24px"
                              height="24px"
                            />
                          </Link>
                        ))}
                      </Flex>
                    </Flex>
                    <TrialTerms />
                    <Flex
                      mt="l"
                      alignItems="center"
                      justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                      flexDirection={{ mobile: 'column', mobileLarge: 'row' }}
                    >
                      <SelectPlanButton />
                      <Flex ml={{ mobile: 0, mobileLarge: 'm' }}>
                        <RequestDemoButton />
                      </Flex>
                    </Flex>

                    <Awards />
                  </Flex>
                </Flex>
              </Flex>
              <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }} mt={{ mobile: 'l', desktop: 0 }}>
                <HeaderImageAnimation />
              </Flex>
            </Grid>
            <Flex mt="xl" pt="m" flexDirection="column" alignItems="center" mb={{ mobile: 'l', desktop: 'l' }}>
              <G2FeaturesComponent />
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="672px" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Manage reviews across the sites that{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                matter to Your Business
              </H1>
            </H3>
          </Flex>
          <Flex mt="xl" width="100%" position="relative">
            <RoundedBox
              position="absolute"
              width="184px"
              height="184px"
              bg={colors.white}
              right="0"
              top={{ mobile: '-54px', tablet: '-74px' }}
              zIndex="0"
            />
            <RoundedBox
              position="absolute"
              width="39px"
              height="39px"
              bg={colors.white}
              right="15px"
              top="146px"
              zIndex="0"
            />
            <RoundedBox
              position="absolute"
              width="393px"
              height="393px"
              bg={colors.white}
              left="-76px"
              bottom="-70px"
              zIndex="0"
            />
            <Grid
              zIndex="1"
              mx="auto"
              gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', tablet: 'repeat(2, auto)' }}
              gridGap="m"
            >
              {REVIEW_ITEMS.map(({ title, description }, index) => {
                const isFirstColumn = index % 2 === 0

                let borderRadius = `${space.xm} ${space.xm} ${space.xs} ${space.xm}`

                if (index === 1) {
                  borderRadius = `${space.xm} ${space.xm} ${space.xm} ${space.xs}`
                } else if (index === 2) {
                  borderRadius = `${space.xm} ${space.xs} ${space.xm} ${space.xm}`
                } else if (index === 3) {
                  borderRadius = `${space.xs} ${space.xm} ${space.xm} ${space.xm}`
                }

                return (
                  <StyledFeatureItemWrapper
                    flexDirection="column"
                    key={title}
                    width={{ mobile: 'auto', desktop: isFirstColumn ? '500px' : '400px' }}
                    borderRadius={{
                      mobile: `${space.xm}`,
                      tablet: borderRadius,
                    }}
                  >
                    <Flex flexDirection="column" mx="xs" p="s">
                      <Flex alignItems="center">
                        <LocalImage src={imageStar} width="18px" height="18px" alt={title} />
                        <Text ml="s" px="xs" color={colors.white} fontWeight="bold" fontSize="xl">
                          {title}
                        </Text>
                      </Flex>
                      <Text mt="m" fontSize="m" color={COLOR_CONSTANTS.SALT} textAlign="left">
                        {description}
                      </Text>
                    </Flex>
                  </StyledFeatureItemWrapper>
                )
              })}
            </Grid>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            What’s included:{' '}
            <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Core Features
            </H1>
          </H3>
          <Flex
            mt="l"
            pt="m"
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 1 }} maxWidth="605px" width="100%">
              <LocalImage src={imageCoreFeatures} width="100%" alt="Core Features" />
            </Flex>
            <Flex order={{ mobile: 1, desktop: 2 }}>
              <Grid gridGap="l" gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', mobileLarge: 'repeat(2, 1fr)' }}>
                {FEATURE_ITEMS.map(({ picture_url, title, description }) => (
                  <Flex
                    pl={{ mobile: '0', tablet: 'm' }}
                    ml={{ mobile: '0', tablet: 'xs' }}
                    flexDirection="column"
                    key={title}
                    maxWidth={{ mobile: '100%', tablet: '255px' }}
                    width="100%"
                  >
                    <Flex alignItems="center">
                      <LocalImage src={picture_url} width="44px" height="44px" alt={title} />
                      <Text ml="m" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="l">
                        {title}
                      </Text>
                    </Flex>
                    <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                      {description}
                    </Text>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <CarouselReviewSection />

      <GetStartedComponent activeKey={REVIEWS} />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Frequently Asked{' '}
            <H3 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Questions
            </H3>
          </H3>
          <Flex flexDirection="column" mt="xl" width="100%">
            <GridDropdown faqs={FREQUENTLY_ASKED_QUESTIONS.reviews} />
            <Flex mt="l" justifyContent="flex-end">
              <StyledLinkText as="a" href="/faq" textAlign="left">
                <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                  ALL OUR FAQS
                </Text>
                <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
              </StyledLinkText>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Footer />
    </App>
  )
}

export default Reviews
