import React from 'react'
import styled from 'styled-components'
import { COLOR_CONSTANTS, colors, radius } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { Flex, Grid } from 'components/Layout'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import Container from 'components/Container'

import imageExpect from 'static/images/solutions_enterprise/expect.webp'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

// TODO:: CHANGE TEXT HERE
const EXPECT_ITEMS = [
  {
    title: 'On-boarding & training',
    description:
      'Our team of platform experts will train your team either in person or online. Online training is unlimited and free.',
  },
  {
    title: 'Dedicated account representative',
    description:
      'Work with a dedicated account rep that will be assigned to your specific account to handle setup, ongoing support, and maintenance of your account.',
  },
  {
    title: 'A customized plan at a budget-friendly cost',
    description:
      "Your specific requirements will be factored into developing a custom plan to support your business's unique needs. Our pricing is quite competitive as well!",
  },
  {
    title: 'Live support',
    description:
      'Our support team does not shy away from our customers. There is always someone to talk to. Schedule unlimited support calls and speak to a live rep!',
  },
]

const ExpectComponent = () => {
  return (
    <Wrapper>
      <Container
        pl={{ mobile: 'l', tablet: 'l' }}
        pr={{ mobile: 'l', tablet: 'l' }}
        py={{ mobile: 'l', tablet: 'xxl' }}
        position="relative"
        maxWidth={CONTAINER_MAX_WIDTH}
        width="100%"
        height="100%"
      >
        <Flex
          justifyContent={{ mobile: 'center', desktop: 'space-between' }}
          flexDirection={{ mobile: 'column', desktop: 'row' }}
          alignItems="center"
        >
          <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 1 }} maxWidth="545px" width="100%">
            <LocalImage src={imageExpect} width="100%" alt="Here’s what you can expect" />
          </Flex>
          <Flex
            order={{ mobile: 1, desktop: 2 }}
            flexDirection="column"
            alignItems={{ mobile: 'center', desktop: 'flex-start' }}
          >
            <Text
              fontWeight="bold"
              fontSize="4xl"
              color={COLOR_CONSTANTS.DENIM}
              textAlign={{ mobile: 'center', desktop: 'left' }}
            >
              Here’s what you{' '}
              <Text as="span" fontWeight="bold" fontSize="4xl" color="primary">
                can expect
              </Text>
            </Text>
            <Grid
              mt="l"
              pt="m"
              gridGap="40px"
              gridTemplateColumns="repeat(1, 1fr)"
              maxWidth={{ mobile: '100%', desktop: '511px' }}
            >
              {EXPECT_ITEMS.map(({ title, description }) => (
                <Flex key={title}>
                  <Flex
                    width="8px"
                    bg={colors.primary}
                    minWidth="8px"
                    borderRadius={`0px ${radius.l} ${radius.l} 0px`}
                  />
                  <Flex ml="m" flexDirection="column">
                    <Text fontWeight="bold" fontSize="l" textAlign="left" color={COLOR_CONSTANTS.DENIM}>
                      {title}
                    </Text>
                    <Text mt="s" fontSize="m" color="secondaryText" textAlign="left">
                      {description}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Grid>
          </Flex>
        </Flex>
      </Container>
    </Wrapper>
  )
}

ExpectComponent.defaultProps = {}

ExpectComponent.propTypes = {}

export default ExpectComponent
