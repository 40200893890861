import React from 'react'

import { Flex, Grid } from 'components/Layout'
import LocalImage from 'components/LocalImage'
import styled from 'styled-components'

import imageCompanyKia from 'static/images/home/companies/kia.svg'
import imageCompanyLensa from 'static/images/home/companies/lensa.svg'
import imageCompanyOracle from 'static/images/home/companies/oracle.svg'
import imageCompanyChickfila from 'static/images/home/companies/chickfila.svg'
import imageCompanyVodafone from 'static/images/home/companies/vodafone.svg'
import imageCompanyWix from 'static/images/home/companies/wix.svg'
import imageCompanyZillow from 'static/images/home/companies/zillow.svg'

const StyledHeaderCompaniesWrapper = styled(Flex)`
  opacity: 0.5;
`

const HEADER_COMPANIES = [
  { key: 'Chick-fil-A', image: imageCompanyChickfila },
  { key: 'Oracle', image: imageCompanyOracle },
  { key: 'Wix', image: imageCompanyWix },
  { key: 'Zillow', image: imageCompanyZillow },
  { key: 'Vodafone', image: imageCompanyVodafone },
  { key: 'Lensa', image: imageCompanyLensa },
  { key: 'Kia', image: imageCompanyKia },
]

const Clients = () => {
  return (
    <StyledHeaderCompaniesWrapper
      alignItems="center"
      flexWrap="wrap"
      justifyContent={{ mobile: 'center', desktop: 'space-between' }}
      my="xl"
      width="100%"
    >
      {HEADER_COMPANIES.map(({ key, image }) => (
        <Flex key={key} mx={{ mobile: 'm', desktop: 0 }} my={{ mobile: 's', tablet: 0 }}>
          <LocalImage src={image} alt={`Vista Social used by ${key}`} />
        </Flex>
      ))}
    </StyledHeaderCompaniesWrapper>
  )
}

export default Clients
