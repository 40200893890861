import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { transparentize } from 'polished'
import App from 'App'
import { COLOR_CONSTANTS, colors, radius, lineHeights, space } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import SEO from 'components/SEO'
import { Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'

import imageHelpCenter from 'static/images/home/help_center.webp'
import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'
import imageDoubleArrowRightWhite from 'static/images/home/double_arrow_right_white.svg'

import Clients from 'components/Clients'
import PoweredBy from 'components/PoweredByAI'
import TrialTerms from 'components/TrialTerms'
import Awards from 'components/Awards'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import CarouselVerticalSection from './components/CarouselVerticalSection'
import BlogItemShort from './components/BlogItemShort'
import CarouselReviewSection from './components/CarouselReviewSection'
import SocialMediasSection from './components/SocialMediasSection'
import SelectPlanButton from './components/SelectPlanButton'
import RequestDemoButton from './components/RequestDemoButton'
import HeaderImageAnimation from './components/HeaderImageAnimation'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const StyledLatestWrapper = styled(Wrapper)`
  background: linear-gradient(180deg, #0250c9 0%, ${colors.primary} 100%);
`

const query = graphql`
  {
    allWordpressPost(filter: { sticky: { eq: true } }, sort: { fields: [date], order: DESC }, limit: 3) {
      edges {
        node {
          id
          path
          title
          excerpt
          date
          modified
          slug
          sticky
          author {
            name
            avatar_urls {
              wordpress_96
            }
          }
          categories {
            slug
            name
            description
          }
          featured_media {
            title
            alt_text
            caption
            source_url
          }
        }
      }
    }
  }
`

// TODO:: convert carousel png images to webp
const Home = () => {
  return (
    <StaticQuery
      query={query}
      render={({ allWordpressPost: { edges } }) => {
        const articles = edges.map(({ node }) => node)
        return (
          <App fullHeader>
            <Fragment>
              <SEO
                title="The most complete social media management platform for brands and agencies"
                description="Expertly crafted social media management tools to help you exceed your goals"
              />

              <Wrapper>
                <Container
                  pl={{ mobile: 'l', tablet: 'l' }}
                  pr={{ mobile: 'l', tablet: 'l' }}
                  position="relative"
                  maxWidth={CONTAINER_MAX_WIDTH}
                  width="100%"
                  height="100%"
                >
                  <Flex flexDirection="column" alignItems="center">
                    <Grid
                      mt="xl"
                      mb="m"
                      gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
                      gridGap="l"
                      width="100%"
                      alignItems="center"
                    >
                      <Flex width="100%" justifyContent="center" alignItems="center">
                        <Flex flexDirection="column">
                          <Flex width="100%" justifyContent="center" flexDirection="column">
                            <PoweredBy />

                            <Flex
                              flexDirection={{ mobile: 'row', desktop: 'column' }}
                              flexWrap="wrap"
                              justifyContent="center"
                              mt="m"
                              maxWidth={{ mobile: '100%', desktop: '500px' }}
                            >
                              <H1
                                fontSize="xxxl"
                                fontWeight="bold"
                                color={COLOR_CONSTANTS.DENIM}
                                textAlign={{ mobile: 'center', desktop: 'left' }}
                              >
                                Social media's best-kept secret for ambitious{' '}
                                <H1
                                  fontSize="xxxl"
                                  fontWeight="bold"
                                  color="primary"
                                  textAlign={{ mobile: 'center', desktop: 'left' }}
                                  as="span"
                                >
                                  brands and agencies
                                </H1>
                              </H1>
                            </Flex>
                            <H2
                              mt="m"
                              pt="s"
                              pr={{ mobile: 0, desktop: 'l' }}
                              color="secondaryText"
                              textAlign={{ mobile: 'center', desktop: 'left' }}
                              fontWeight="normal"
                              fontSize="m"
                              lineHeight={lineHeights.xxl}
                            >
                              Unlock the full potential of social with the ultimate all-in-one social media management
                              platform. Scale your marketing and achieve goals beyond imagination!
                            </H2>
                            <TrialTerms />
                            <Flex
                              mt="l"
                              alignItems="center"
                              justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                              flexDirection={{ mobile: 'column', mobileLarge: 'row' }}
                            >
                              <SelectPlanButton />
                              <Flex ml={{ mobile: 0, mobileLarge: 'm' }}>
                                <RequestDemoButton />
                              </Flex>
                            </Flex>

                            <Awards />
                          </Flex>
                        </Flex>
                      </Flex>
                      <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }}>
                        <HeaderImageAnimation />
                      </Flex>
                    </Grid>
                  </Flex>
                  <Clients />

                  <Flex mt="s" pt="s" flexDirection="column" alignItems="center" mb={{ mobile: 'l', desktop: 'l' }}>
                    <G2FeaturesComponent />
                  </Flex>
                </Container>
              </Wrapper>

              <CarouselVerticalSection />

              <SocialMediasSection />

              <CarouselReviewSection />

              <Wrapper>
                <Container
                  my="xl"
                  pl={{ mobile: 'l', tablet: 'l' }}
                  pr={{ mobile: 'l', tablet: 'l' }}
                  position="relative"
                  maxWidth={CONTAINER_MAX_WIDTH}
                  width="100%"
                  height="100%"
                >
                  <Flex
                    justifyContent="space-between"
                    width="100%"
                    flexDirection={{ mobile: 'column', desktop: 'row' }}
                    alignItems="center"
                  >
                    <Flex flexDirection="column" width={{ mobile: '100%', tablet: '539px' }}>
                      <Text fontSize="l" color="secondaryText" textAlign="left">
                        Customer support
                      </Text>
                      <Text textAlign="left">
                        <Text fontSize="3xl" as="span" fontWeight="bold" color={COLOR_CONSTANTS.SALUTE}>
                          And we’re here
                        </Text>
                        <Text fontSize="3xl" as="span" fontWeight="bold" color="primary">
                          {' '}
                          to Help
                        </Text>
                      </Text>
                      <Text mt="m" pt="s" fontSize="l" color="secondaryText" textAlign="left">
                        Our customer advocates stand by 24/7 to support you via email and social media. We also have a
                        comprehensive, regularly updated help center for those who prefer to find help directly. If you
                        are feeling social, we have a supportive community of creators, small business owners, and
                        marketers on social media.
                      </Text>
                      <Flex mt="xl" alignItems="center">
                        <StyledLinkText as="a" href="https://support.vistasocial.com/" target="_blank" textAlign="left">
                          <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                            Visit our help center
                          </Text>
                          <LocalImage src={imageDoubleArrowRightBlue} />
                        </StyledLinkText>
                      </Flex>
                    </Flex>

                    <LocalImage
                      src={imageHelpCenter}
                      maxWidth="537px"
                      width="100%"
                      loading="lazy"
                      alt="Customer support"
                    />
                  </Flex>
                </Container>
              </Wrapper>

              <StyledLatestWrapper>
                <Container
                  my="xl"
                  pl={{ mobile: 'l', tablet: 0 }}
                  pr={{ mobile: 'l', tablet: 0 }}
                  position="relative"
                  maxWidth={CONTAINER_MAX_WIDTH}
                  width="100%"
                  height="100%"
                >
                  <Flex
                    justifyContent="space-between"
                    width="100%"
                    alignItems="flex-end"
                    flexDirection={{ mobile: 'column', tablet: 'row' }}
                    px="l"
                  >
                    <Flex flexDirection="column">
                      <Text fontSize="3xl" color="white" textAlign="left">
                        Learn about the latest social media strategies,
                      </Text>
                      <Flex width="fit-content" bg={transparentize(0.8, colors.white)} borderRadius={radius.m}>
                        <Text fontSize="3xl" color="white" textAlign="left">
                          so you can test & iterate
                        </Text>
                      </Flex>
                    </Flex>
                    <StyledLinkText as="a" href="/insights" textAlign="left">
                      <Text fontSize="l" fontWeight="medium" textAlign="left" color="white" as="span">
                        READ MORE ARTICLES
                      </Text>
                      <LocalImage src={imageDoubleArrowRightWhite} />
                    </StyledLinkText>
                  </Flex>
                  <Flex mt="xl" flexWrap="wrap" justifyContent="center" px={{ mobile: 'm', tablet: '0' }}>
                    {articles.slice(0, 3).map((article, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <BlogItemShort key={index} {...article} index={index} />
                    ))}
                  </Flex>
                </Container>
              </StyledLatestWrapper>

              <Footer />
            </Fragment>
          </App>
        )
      }}
    />
  )
}

Home.propTypes = {
  pageContext: PropTypes.shape({
    filterData: PropTypes.object.isRequired,
    agencies: PropTypes.array,
    tools: PropTypes.array,
    events: PropTypes.array,
  }).isRequired,
}

export default Home
