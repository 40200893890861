import React from 'react'
import styled from 'styled-components'
import { COLOR_CONSTANTS, colors, fontSizes, space } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { pxToRem } from 'helpers'
import { Flex, Box } from 'components/Layout'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import Container from 'components/Container'
import Button from 'components/Button'

import imageLock from 'static/images/solutions_enterprise/lock.webp'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const StyledLearnMoreHostingButton = styled(Button.Reversed)`
  font-size: ${fontSizes.m};
  height: 100%;
  padding-left: ${space.l};
  padding-right: ${space.l};
  background: transparent;
  color: ${COLOR_CONSTANTS.WHITE};
  &:hover {
    background: ${COLOR_CONSTANTS.WHITE};
    color: ${colors.primary};
  }
`
const SecurePlatformComponent = () => {
  return (
    <Wrapper bg="#024CBB">
      <Box maxWidth="1920px" mx="auto" position="relative" width="100%">
        <Box position="absolute" height={{ mobile: '100%', desktopWide: 'auto' }}>
          <LocalImage
            src={imageLock}
            width="100%"
            alt="Transparent practices, reliable systems and a Secure Platform"
            maxWidth={{ mobile: '100%', desktopWide: '1032px' }}
            height={{ mobile: '100%', desktopWide: 'auto' }}
          />
        </Box>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', desktop: 'xxl', desktopWide: '0' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height={{ mobile: 'auto', desktopWide: '604px' }}
        >
          <Flex
            justifyContent={{ mobile: 'center', desktopWide: 'space-between' }}
            flexDirection={{ mobile: 'column', desktopWide: 'row' }}
            alignItems="center"
            height="100%"
          >
            <Flex order={{ mobile: 2, desktop: 2 }} display={{ mobile: 'none', desktopWide: 'flex' }} width="50%" />
            <Flex
              order={{ mobile: 1, desktopWide: 2 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktopWide: 'flex-start' }}
              justifyContent="center"
              maxWidth={{ mobile: '100%', desktopWide: '50%' }}
              position="relative"
              height="100%"
            >
              <Text
                fontWeight="bold"
                fontSize="3xl"
                color={COLOR_CONSTANTS.WHITE}
                textAlign={{ mobile: 'center', desktopWide: 'left' }}
              >
                Transparent practices, reliable systems and a{' '}
                <Text as="span" fontSize="3xl" color={COLOR_CONSTANTS.WHITE} fontStyle="italic">
                  Secure Platform
                </Text>
              </Text>
              <Text
                mt="m"
                pt="s"
                color={COLOR_CONSTANTS.SALT}
                fontSize="l"
                textAlign={{ mobile: 'center', desktopWide: 'left' }}
              >
                A relationship with Vista Social is built on trust. That's why we prioritize security and data privacy
                for all customers.
              </Text>

              <Flex mt="xl">
                <Flex height={{ mobile: pxToRem(56), desktopWide: pxToRem(52) }}>
                  <StyledLearnMoreHostingButton as="a" href="/security">
                    Learn more about hosting and security
                  </StyledLearnMoreHostingButton>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Wrapper>
  )
}

SecurePlatformComponent.defaultProps = {}

SecurePlatformComponent.propTypes = {}

export default SecurePlatformComponent
