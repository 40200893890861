import React from 'react'

import { Flex, Grid } from 'components/Layout'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import { COLOR_CONSTANTS } from 'theme'
import imageG2Logo from 'static/images/home/g2_logo.svg'

const year = new Date().getFullYear()

const HEADER_G2_TOP_ITEMS = [
  { text: `Top 5 Fastest Growing Products, ${year}` },
  { text: `Top 10 Highest Satisfaction Products, ${year}` },
  // { text: 'Top 10 Highest Rated SMM Products, 2024' },
  // { text: 'Top 100 Products for Mid Market, 2024' },
]

const Awards = () => {
  return (
    <Flex mt="l" pt="m" flexDirection="column" justifyContent="center">
      <Flex alignItems="center" justifyContent={{ mobile: 'center', desktop: 'flex-start' }}>
        <LocalImage src={imageG2Logo} />
        <Text ml="m" fontSize="l" fontWeight="medium" color={COLOR_CONSTANTS.SAPPHIRE}>
          #1 Easiest to use software | {year}
        </Text>
      </Flex>

      <Grid
        mt="m"
        gridTemplateColumns={{ mobile: 'repeat(1, auto)', mobileLarge: 'repeat(2, auto)' }}
        gridGap="m"
        justifyContent={{ mobile: 'center', desktop: 'unset' }}
      >
        {HEADER_G2_TOP_ITEMS.map(({ text }) => (
          <Flex key={text} alignItems="center">
            <Text fontSize="xs" color={COLOR_CONSTANTS.SAPPHIRE} textAlign="left">
              {text}
            </Text>
          </Flex>
        ))}
      </Grid>
    </Flex>
  )
}

export default Awards
