import React from 'react'

import styled from 'styled-components'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import { space, COLOR_CONSTANTS } from 'theme'
import imagePoweredByAI from 'static/images/home/powered_by_ai.svg'

const StyledPoweredByAIWrapper = styled(Flex)`
  align-items: center;
  padding: ${space.s} ${space.m};
  background: ${COLOR_CONSTANTS.SALT};
  border-radius: 47px;
  max-width: fit-content;
`

const PoweredByAI = ({ message = 'POWERED BY AI' }) => {
  return (
    <Flex flexDirection={{ mobile: 'row', desktop: 'column' }} justifyContent="center">
      <StyledPoweredByAIWrapper>
        <LocalImage src={imagePoweredByAI} width="20px" height="20px" />
        <Text color="primary" fontWeight="medium" fontSize="s" ml="s" pl="xxs">
          {message}
        </Text>
      </StyledPoweredByAIWrapper>
    </Flex>
  )
}

export default PoweredByAI
