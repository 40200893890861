import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, lineHeights } from 'theme'
import { CONTAINER_MAX_WIDTH, ENGAGEMENT, FREQUENTLY_ASKED_QUESTIONS } from 'consts'
import SEO from 'components/SEO'
import { Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2, H3 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import GetStartedComponent from 'components/GetStartedComponent'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import imageInboxFeatures from 'static/images/mobile_app/inbox_features.webp'

import imageEngagementFeatures from 'static/images/engagement/engagement_features.webp'
import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'

import featureImageInbox from 'static/images/engagement/features/inbox.svg'
import featureImageConversation from 'static/images/engagement/features/conversation.svg'
import featureImageTask from 'static/images/engagement/features/task.svg'
import featureImageMessage from 'static/images/engagement/features/message.svg'
import featureImageEngagement from 'static/images/engagement/features/engagement.svg'
import featureImageNotes from 'static/images/engagement/features/notes.svg'

import PoweredBy from 'components/PoweredByAI'
import TrialTerms from 'components/TrialTerms'
import Awards from '../../components/Awards'
import RequestDemoButton from '../Home/components/RequestDemoButton'

import GridDropdown from '../Faq/GridDropdown'
import CarouselReviewSection from '../Home/components/CarouselReviewSection'
import SelectPlanButton from '../Home/components/SelectPlanButton'
import HeaderImageAnimation from './HeaderImageAnimation'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const FEATURE_ITEMS = [
  {
    picture_url: featureImageInbox,
    title: 'Smart Inbox',
    description:
      'Unify all connected networks and profiles into a single stream to monitor incoming messages and provide quick responses.',
  },
  {
    picture_url: featureImageConversation,
    title: 'Conversation View',
    description:
      'All messages with a user are shown in one view facilitating easy access to prior chats with a given user.',
  },
  {
    picture_url: featureImageTask,
    title: 'Task Assignment',
    description:
      'Delegate messages to specific team members and measure users’ efficiency based on tasks assigned and completed.',
  },
  {
    picture_url: featureImageMessage,
    title: 'Message Tagging',
    description:
      'Label and sort messages to easily access communications related to marketing strategies, business objectives, and specific workflows.',
  },
  {
    picture_url: featureImageNotes,
    title: 'Internal Notes',
    description: 'Create internal notes and mention team members within conversations.',
  },
  {
    picture_url: featureImageEngagement,
    title: 'Engagement Features',
    description:
      'Engagement with messages right from the inbox with features to like, dislike, delete messages and block users.',
  },
]

const Engagement = () => {
  return (
    <App>
      <SEO
        title="Reply to social media comments and messages from one place."
        description="Manage all of your comments, messages, reviews and mentions, stay on top of important interactions, and turn followers into fans using Vista Social."
        path="social-media-engagement"
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              mb={{ mobile: 'l', desktop: 'xl' }}
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
            >
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column">
                  <Flex width="100%" justifyContent="center" flexDirection="column">
                    <PoweredBy />
                    <Flex
                      mt="m"
                      flexDirection={{ mobile: 'row', desktop: 'column' }}
                      flexWrap="wrap"
                      justifyContent="center"
                    >
                      <H1
                        fontSize="xxxl"
                        fontWeight="bold"
                        color={COLOR_CONSTANTS.DENIM}
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                      >
                        Reply to social media comments and messages{' '}
                        <H1
                          fontSize="xxxl"
                          fontWeight="bold"
                          color="primary"
                          textAlign={{ mobile: 'center', desktop: 'left' }}
                          as="span"
                        >
                          from one place
                        </H1>
                      </H1>
                    </Flex>
                    <H2
                      mt="m"
                      pt="s"
                      color="secondaryText"
                      fontSize="m"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                      lineHeight={lineHeights.xxl}
                    >
                      Manage all of your comments, messages, reviews and mentions, stay on top of important
                      interactions, and turn followers into fans using Vista Social.
                    </H2>
                    <TrialTerms />
                    <Flex
                      mt="l"
                      alignItems="center"
                      justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                      flexDirection={{ mobile: 'column', mobileLarge: 'row' }}
                    >
                      <SelectPlanButton />

                      <Flex ml={{ mobile: 0, mobileLarge: 'm' }}>
                        <RequestDemoButton />
                      </Flex>
                    </Flex>
                    <Awards />
                  </Flex>
                </Flex>
              </Flex>
              <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }} mt={{ mobile: 'l', desktop: 0 }}>
                <HeaderImageAnimation />
              </Flex>
            </Grid>
            <Flex
              mt={{ mobile: 'l', tablet: 'xl' }}
              pt="m"
              flexDirection="column"
              alignItems="center"
              mb={{ mobile: 'l', desktop: 'l' }}
            >
              <G2FeaturesComponent />
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="680px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Reply to comments, messages and reviews from
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                {` `}Desktop{` `}
              </H1>
              or
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                {` `}Mobile
              </H1>
            </H3>
            <Text mt="m" pt="s" color="secondaryText" fontSize="l">
              Overflowing inboxes and time-consuming tasks affect your team’s ability to deliver relationship -building
              experiences on social. From community management to customer support, Vista Social gives you the tools you
              need to increase visibility, improve responsiveness and boost efficiency.
            </Text>
          </Flex>

          <Flex mt="xl" justifyContent={{ mobile: 'center', desktop: 'space-between' }}>
            <LocalImage
              src={imageEngagementFeatures}
              maxWidth="935px"
              width="100%"
              mx="auto"
              alt="Reply to comments, messages and reviews from Your Desktop"
            />
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="750px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Harness the Power of{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                AI Reply Assistant
              </H1>
            </H3>
            <Text mt="m" pt="s" color="secondaryText" fontSize="l">
              Stay on top with smart inbox for quick responses across social profiles.
            </Text>
          </Flex>

          <Flex mt="xl" justifyContent={{ mobile: 'center', desktop: 'space-between' }} as="a" href="/pricing">
            <LocalImage
              src={imageInboxFeatures}
              maxWidth="1031px"
              width="100%"
              mx="auto"
              alt="Harness the Power of AI Reply Assistant"
            />
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            What’s included:{' '}
            <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Core Features
            </H1>
          </H3>
          <Grid
            mt="xl"
            gridGap="l"
            gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', mobileLarge: 'repeat(2, 1fr)', desktop: 'repeat(3, 1fr)' }}
          >
            {FEATURE_ITEMS.map(({ title, description, picture_url }) => (
              <Flex
                flexDirection="column"
                key={title}
                maxWidth={{ mobile: '100%', mobileLarge: '350px' }}
                width="100%"
                height={{ mobile: 'auto', tablet: '182px' }}
              >
                <Flex alignItems="center">
                  <LocalImage src={picture_url} width="44px" height="44px" alt={title} />
                  <Text ml="m" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="l">
                    {title}
                  </Text>
                </Flex>
                <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                  {description}
                </Text>
              </Flex>
            ))}
          </Grid>
        </Container>
      </Wrapper>

      <CarouselReviewSection />

      <GetStartedComponent activeKey={ENGAGEMENT} />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Frequently Asked{' '}
            <H3 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Questions
            </H3>
          </H3>
          <Flex flexDirection="column" mt="xl" width="100%">
            <GridDropdown faqs={FREQUENTLY_ASKED_QUESTIONS.inbox} />
            <Flex mt="l" justifyContent="flex-end">
              <StyledLinkText as="a" href="/faq" textAlign="left">
                <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                  ALL OUR FAQS
                </Text>
                <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
              </StyledLinkText>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Footer />
    </App>
  )
}

export default Engagement
