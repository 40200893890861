import React from 'react'
import styled from 'styled-components'
import App from 'App'
import Marquee from 'react-fast-marquee'
import { CONTAINER_MAX_WIDTH, FREQUENTLY_ASKED_QUESTIONS } from 'consts'
import { COLOR_CONSTANTS, radius, fontSizes, colors, lineHeights } from 'theme'
import { pxToRem } from 'helpers'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import { Text, H1, H2, H3 } from 'components/Typography'
import Container from 'components/Container'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import Button from 'components/Button'

import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'
import imageHeader from 'static/images/vista_page/header_picture.webp'
import imageMakeSpecial from 'static/images/vista_page/make_special.webp'
import imageDriveTrafficAndClicks from 'static/images/vista_page/drive_traffic_and_clicks.webp'
import imageIntegrateMoreTools from 'static/images/vista_page/integrate_more_tools.webp'
import imageUrlShowsOff from 'static/images/vista_page/url_shows_off.webp'

import imageTemplate1 from 'static/images/vista_page/templates/template_1.webp'
import imageTemplate2 from 'static/images/vista_page/templates/template_2.webp'
import imageTemplate3 from 'static/images/vista_page/templates/template_3.webp'
import imageTemplate4 from 'static/images/vista_page/templates/template_4.webp'
import imageTemplate5 from 'static/images/vista_page/templates/template_5.webp'
import imageTemplate6 from 'static/images/vista_page/templates/template_6.webp'
import imageTemplate7 from 'static/images/vista_page/templates/template_7.webp'
import imageTemplate8 from 'static/images/vista_page/templates/template_8.webp'
import imageTemplate9 from 'static/images/vista_page/templates/template_9.webp'
import imageTemplate10 from 'static/images/vista_page/templates/template_10.webp'
import imageTemplate11 from 'static/images/vista_page/templates/template_11.webp'
import imageTemplate12 from 'static/images/vista_page/templates/template_12.webp'

import PoweredBy from 'components/PoweredByAI'
import TrialTerms from 'components/TrialTerms'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import GridDropdown from '../Faq/GridDropdown'
import { space } from '../../theme'
import Awards from '../../components/Awards'
import RequestDemoButton from '../Home/components/RequestDemoButton'

import SelectPlanButton from '../Home/components/SelectPlanButton'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  overflow-x: hidden;
`

const StyledHeaderWrapper = styled(Wrapper)`
  overflow: hidden;
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const StyledGetStartedButtonWrapper = styled(Flex)`
  box-shadow: 0px 18px 24px -8px rgb(36 130 253 / 32%);
`

const StyledButtonGradient = styled(Button.Gradient)`
  height: 100%;
  width: 240px;
`

const StyledWhatIsVistaPageWrapper = styled(Flex)`
  gap: ${space.l};
`

const TEMPLATES_IMAGES = [
  { image: imageTemplate1 },
  { image: imageTemplate2 },
  { image: imageTemplate3 },
  { image: imageTemplate4 },
  { image: imageTemplate5 },
  { image: imageTemplate6 },
  { image: imageTemplate7 },
  { image: imageTemplate8 },
  { image: imageTemplate9 },
  { image: imageTemplate10 },
  { image: imageTemplate11 },
  { image: imageTemplate12 },
]

const MAKE_YOUR_PAGES_SPECIAL__ITEMS = [
  {
    title: 'Create and customize',
    description: 'Choose from a wide selection of themes and customize them to your brand’s specific needs',
  },
  { title: 'Integrations', description: 'Integrate videos, calendars, images, forms, payments and lead gen tools' },
  {
    title: 'Track performance',
    description: 'Analyze the performance using built in reporting or using Google Analytics',
  },
  {
    title: 'Custom domain',
    description: 'Use our default domain or implement your own domain. SSL certificates are free.',
  },
]

const VistaPage = () => {
  return (
    <App fullHeader>
      <SEO
        title="Link in bio, mini website and landing page tool from Vista Social | Vista Page"
        description="Use Vista Page from Vista Social to create a fully-customizable link in bio web pages, micro sites and landing pages. Drive traffic from Instagram & TikTok, track clicks, and more."
        path="/vista-page/"
      />

      <StyledHeaderWrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex mb="xl" flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              pt={{ mobile: 0, desktop: 'm' }}
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
            >
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column">
                  <Flex width="100%" justifyContent="center" flexDirection="column" maxWidth="536px">
                    <PoweredBy />
                    <Flex
                      mt="m"
                      flexDirection={{ mobile: 'row', desktop: 'column' }}
                      flexWrap="wrap"
                      justifyContent="center"
                    >
                      <H1
                        fontSize="xxxl"
                        fontWeight="bold"
                        color={COLOR_CONSTANTS.DENIM}
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                      >
                        Turn followers into customers with Vista Social
                        <H1
                          as="span"
                          fontSize="xxxl"
                          fontWeight="bold"
                          color="primary"
                          textAlign={{ mobile: 'center', desktop: 'left' }}
                        >
                          {' '}
                          link in bio{' '}
                        </H1>
                        tool
                      </H1>
                    </Flex>
                    <H2
                      mt="m"
                      color="secondaryText"
                      fontSize="m"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                      lineHeight={lineHeights.xxl}
                    >
                      Use Vista Page to create a stunning fully-customizable link in bio and landing pages for your
                      business or brand. Drive traffic from social networks, collect payments, generate leads, get
                      appointments, track clicks, and more.
                    </H2>
                  </Flex>
                  <TrialTerms />
                  <Flex
                    mt="l"
                    alignItems="center"
                    justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                    flexDirection={{ mobile: 'column', mobileLarge: 'row' }}
                  >
                    <SelectPlanButton />

                    <Flex ml={{ mobile: 0, mobileLarge: 'm' }}>
                      <RequestDemoButton />
                    </Flex>
                  </Flex>
                  <Awards />
                </Flex>
              </Flex>
              <Flex
                width="100%"
                ml="auto"
                mr={{ mobile: 'auto', desktop: 0 }}
                mt={{ mobile: 'l', desktop: 0 }}
                position="relative"
              >
                <LocalImage
                  src={imageHeader}
                  width="100%"
                  mx="auto"
                  maxWidth="615px"
                  alt="Vista Page link in bio, mini website and landing page tool"
                />
              </Flex>
            </Grid>
            <Flex
              mt={{ mobile: 'l', tablet: 'xl' }}
              pt="m"
              flexDirection="column"
              alignItems="center"
              mb={{ mobile: 'l', desktop: 'l' }}
            >
              <G2FeaturesComponent />
            </Flex>
          </Flex>
        </Container>
      </StyledHeaderWrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          pt={{ mobile: 'l', tablet: 'xxl' }}
          pb={{ mobile: 'l', tablet: 'xl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="730px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              What is{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                Vista Page
              </H1>
              ?
            </H3>
            <Text mt="m" pt="s" color="secondaryText" fontSize="l">
              Vista Page is a mini web page built into your social network profiles — making your content clickable and
              shoppable. Create your page, drop the link in your bio, and help followers discover more about you, your
              products, and your brand.
            </Text>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Box position="absolute" height="50%" bg={COLOR_CONSTANTS.SALT} width="100%" />
        <Box width="100%">
          <Marquee pauseOnHover speed={30}>
            {TEMPLATES_IMAGES.map(({ image }, index) => {
              return (
                <Box height={{ mobile: '320px', tablet: '516px' }} px="m" key={index}>
                  <LocalImage height="100%" width="auto" src={image} alt="Vista page example template" />
                </Box>
              )
            })}
          </Marquee>
        </Box>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth="1110px"
          width="100%"
          height="100%"
        >
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 2 }} maxWidth="538px" width="100%">
              <LocalImage
                src={imageMakeSpecial}
                width="100%"
                alt="Vista Page offers endless customizations and integrations options"
              />
            </Flex>
            <Flex
              order={{ mobile: 1, desktop: 1 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
              maxWidth={{ mobile: '100%', desktop: '570px' }}
            >
              <Text
                fontWeight="bold"
                fontSize="3xl"
                color={COLOR_CONSTANTS.DENIM}
                textAlign={{ mobile: 'center', desktop: 'left' }}
              >
                Make your pages
                <Text as="span" fontSize="3xl" fontWeight="bold" color="primary">
                  {' '}
                  special
                </Text>
              </Text>

              <Text mt="s" color="secondaryText" fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
                Endless customizations and integrations options
              </Text>

              <Grid
                mt="l"
                pt="m"
                gridGap="44px"
                gridTemplateColumns={{
                  mobile: 'repeat(1, 1fr)',
                  tablet: 'repeat(2, 1fr)',
                }}
              >
                {MAKE_YOUR_PAGES_SPECIAL__ITEMS.map(({ title, description }) => (
                  <Flex key={title}>
                    <Flex
                      width="8px"
                      bg={colors.primary}
                      minWidth="8px"
                      borderRadius={`0px ${radius.l} ${radius.l} 0px`}
                    />
                    <Flex ml="m" flexDirection="column">
                      <Text fontWeight="bold" fontSize="l" textAlign="left" color={COLOR_CONSTANTS.DENIM}>
                        {title}
                      </Text>
                      <Text mt="s" fontSize="m" color="secondaryText" textAlign="left">
                        {description}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              </Grid>

              <Flex
                mt="l"
                pt="m"
                pb={{ mobile: 'm', tablet: '0' }}
                justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
              >
                <StyledGetStartedButtonWrapper height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}>
                  <StyledButtonGradient as="a" href="/pricing">
                    <Text p="m" fontSize="s">
                      Build your Vista Page
                    </Text>
                  </StyledButtonGradient>
                </StyledGetStartedButtonWrapper>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          pt="0"
          pb="0"
          position="relative"
          maxWidth="1110px"
          width="100%"
          height="100%"
        >
          <Flex
            flexDirection="column"
            bg={COLOR_CONSTANTS.SALT}
            borderRadius="24px"
            width="100%"
            pt={{ mobile: 'l', tablet: 'xxl' }}
            pb={{ mobile: 'l', tablet: 'xl' }}
            px="l"
          >
            <Flex maxWidth="485px" flexDirection="column" mx="auto">
              <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
                Here is what Vista Page{' '}
                <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                  link in bio tool{' '}
                </H1>
                can do
              </H3>
            </Flex>
            <Text mt="m" pt="s" color="secondaryText" fontSize="l">
              Manage all your links from one easy-to-use platform.
              <br />
              Curate, sell, grow, and engage your audience all from one link
            </Text>

            <StyledWhatIsVistaPageWrapper mt="l" pt="m" flexDirection="column">
              <StyledWhatIsVistaPageWrapper flexDirection={{ mobile: 'column', tablet: 'row' }}>
                <Flex
                  flexDirection="column"
                  borderRadius="24px"
                  bg={COLOR_CONSTANTS.WHITE}
                  p="l"
                  width={{ mobile: '100%', tablet: '50%' }}
                >
                  <Flex alignItems="center">
                    <Box borderRadius={radius.pill} width="8px" height="8px" minWidth="8px" bg={colors.primary} />
                    <Text ml="s" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl">
                      Drive traffic and clicks
                    </Text>
                  </Flex>
                  <Text mt="s" color="secondaryText" fontSize="m" textAlign="left">
                    Your Vista Page takes visitors past your profile to anywhere else on the web. Whether it’s a blog
                    post or product page with your latest offer — you decide where your followers go next
                  </Text>

                  <LocalImage
                    mt={`-${space.l}`}
                    src={imageDriveTrafficAndClicks}
                    width="100%"
                    alt="Vista Page drive traffic and clicks using default or custom domain"
                  />
                </Flex>

                <Flex
                  flexDirection="column"
                  borderRadius="24px"
                  bg={COLOR_CONSTANTS.WHITE}
                  p="l"
                  width={{ mobile: '100%', tablet: '50%' }}
                >
                  <LocalImage
                    mt={`-${space.l}`}
                    src={imageUrlShowsOff}
                    width="100%"
                    alt="A URL that shows off your brand"
                  />
                  <Flex mt="m" alignItems="center">
                    <Box borderRadius={radius.pill} width="8px" height="8px" minWidth="8px" bg={colors.primary} />
                    <Text ml="s" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl">
                      A URL that shows off your brand
                    </Text>
                  </Flex>
                  <Text mt="s" color="secondaryText" fontSize="m" textAlign="left">
                    Use a build in vista.page domain or implement your own custom domain to drive further brand
                    awareness
                  </Text>
                </Flex>
              </StyledWhatIsVistaPageWrapper>

              <Flex flexDirection="row" borderRadius="24px" bg={COLOR_CONSTANTS.WHITE} p="l">
                <Grid
                  gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
                  gridGap="l"
                  width="100%"
                  alignItems="center"
                >
                  <Flex flexDirection="column">
                    <Flex alignItems="center">
                      <Box borderRadius={radius.pill} width="8px" height="8px" minWidth="8px" bg={colors.primary} />
                      <Text ml="s" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl">
                        Integrate more tools
                      </Text>
                    </Flex>
                    <Text mt="s" color="secondaryText" fontSize="m" textAlign="left">
                      Easily connect your favorite tools like Typeform, Calendly, and YouTube using built in Google Tag
                      Manager integration
                    </Text>
                  </Flex>

                  <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }} position="relative">
                    <LocalImage
                      src={imageIntegrateMoreTools}
                      width="100%"
                      mx="auto"
                      maxWidth="458px"
                      alt="Vista Social integrations include typeform, calendly, youtube and google tag manager."
                    />
                  </Flex>
                </Grid>
              </Flex>
            </StyledWhatIsVistaPageWrapper>

            <Flex mt="l" pt="m" pb={{ mobile: 'm', tablet: '0' }} justifyContent="center">
              <StyledGetStartedButtonWrapper height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}>
                <StyledButtonGradient as="a" href="/pages">
                  <Text p="m" fontSize="s">
                    Build your Vista Page
                  </Text>
                </StyledButtonGradient>
              </StyledGetStartedButtonWrapper>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Frequently Asked{' '}
            <H3 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Questions
            </H3>
          </H3>
          <Flex flexDirection="column" mt="xl" width="100%">
            <GridDropdown faqs={FREQUENTLY_ASKED_QUESTIONS.vista_page} />
            <Flex mt="l" justifyContent="flex-end">
              <StyledLinkText as="a" href="/faq" textAlign="left">
                <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                  ALL OUR FAQS
                </Text>
                <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
              </StyledLinkText>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Footer />
    </App>
  )
}

export default VistaPage
