import React, { useEffect } from 'react'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, radius, space } from 'theme'
import SEO from 'components/SEO'
import { Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'

import imageBackground from 'static/images/social-in-seconds/background_image.webp'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const BgImgBackground = styled(LocalImage)`
  pointer-events: none;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  left: ${({ left }) => left || '50%'};
  top: ${({ top }) => top || 0};
  transform: translate(-50%);
  height: ${({ height }) => height || 'auto'};
  object-fit: cover;
  border-radius: ${({ borderRadius }) => borderRadius || 'unset'};
  width: 100%;
`

const StyledText = styled(Text)`
  font-family: 'Amarante', Poppins;
`

const StyledFormWrapper = styled(Flex)`
  background: ${COLOR_CONSTANTS.WHITE};
  border-radius: ${radius.xxl};
  padding: ${space.m};
`

const SocialInSeconds = () => {
  const addExternalScript = (url, callback) => {
    const script = document.createElement('script')
    script.src = url
    script.async = true

    if (callback) {
      script.onload = callback
    }

    document.body.appendChild(script)
  }

  useEffect(() => {
    addExternalScript('//js.hsforms.net/forms/embed/v2.js', () => {
      addExternalScript('/social-in-seconds/hbspt_script.js')
    })
  }, [])

  return (
    <App fullHeader>
      <link href="https://fonts.googleapis.com/css2?family=Amarante&display=swap" rel="stylesheet" />

      <SEO
        title="Social in Seconds."
        description="Unlock rare (snackable) social snapshots, delivered straight to your inbox!"
        path="/social-in-seconds/"
      />

      <Wrapper bg="linear-gradient(255.81deg, #0063E3 -23.38%, #0443B7 20.3%, #01235A 64.57%)" position="relative">
        <BgImgBackground
          src={imageBackground}
          width="auto"
          height="100%"
          display={{ mobile: 'none', tablet: 'block' }}
        />

        <Container
          pl={{ mobile: 'l', desktop: 'm' }}
          pr={{ mobile: 'l', desktop: 'm' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth="1110px"
          width="100%"
          height="100%"
          zIndex="1"
        >
          <Grid gridTemplateColumns={{ mobile: '1fr', desktop: 'repeat(2, 1fr)' }} gridGap="l">
            <Flex flexDirection="column" maxWidth={{ mobile: '100%', desktop: '529px' }}>
              <Flex
                maxWidth={{ mobile: '100%', desktop: '495px' }}
                mt={{ mobile: 0, desktop: `-${space.m}` }}
                flexDirection="column"
              >
                <StyledText color="white" fontSize="72px" textAlign={{ mobile: 'center', desktop: 'left' }}>
                  Social in
                </StyledText>
                <StyledText
                  as="span"
                  color="primary"
                  fontSize={{ mobile: '72px', mobileLarge: '108px' }}
                  lineHeight="72px"
                  textAlign={{ mobile: 'center', desktop: 'left' }}
                >
                  SECONDS
                </StyledText>
              </Flex>

              <Flex mt="l" pt="m" flexDirection="column">
                <Text textAlign={{ mobile: 'center', desktop: 'left' }} color="white" fontSize="xl">
                  Get the real, real inside scoop on social media secrets, exclusively delivered by{' '}
                  <Text as="span" color="#C1FF72" fontSize="xl">
                    Lady Scrollsworth
                  </Text>
                  .
                  <br />
                  <br />
                  Unlock rare (snackable) social snapshots, delivered straight to your inbox!
                  <br />
                  <br />
                  Wield these secrets wisely brave-heart.
                </Text>
              </Flex>
            </Flex>

            <Flex alignItems="center" width="100%" maxWidth="532.5px" mx="auto">
              <StyledFormWrapper flexDirection="column" id="hubspot-form" width="100%" />
            </Flex>
          </Grid>
        </Container>
      </Wrapper>

      <Footer />
    </App>
  )
}

export default SocialInSeconds
