import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR_CONSTANTS, space, colors, radius } from 'theme'
import { INTEGRATION_TABS, CONTAINER_MAX_WIDTH } from 'consts'
import { Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2 } from 'components/Typography'
import LocalImage from 'components/LocalImage'

import PoweredBy from 'components/PoweredByAI'
import TrialTerms from 'components/TrialTerms'
import Awards from 'components/Awards'
import SelectPlanButton from '../../../Home/components/SelectPlanButton'
import { changeTextColorByMatch } from '../../helper'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const StyledCategories = styled.a`
  text-decoration: none;
  padding: ${space.xs} ${space.s};
  margin-right: ${space.s};
  margin-top: ${space.s};
  background-color: ${({ isActive }) => (isActive ? colors.primary : 'rgba(84, 133, 255, 0.15)')};
  border-radius: ${radius.l};
  cursor: pointer;
`

const PageHeader = ({ network, headerText, description, image }) => {
  return (
    <Wrapper>
      <Container
        pl={{ mobile: 'l', tablet: 'l' }}
        pr={{ mobile: 'l', tablet: 'l' }}
        position="relative"
        maxWidth={CONTAINER_MAX_WIDTH}
        width="100%"
        height="100%"
      >
        <Flex flexDirection="column" alignItems="center">
          <Grid
            mt="xl"
            pt={{ mobile: 0, desktop: 'm' }}
            gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
            gridGap="l"
            width="100%"
            alignItems="center"
          >
            <Flex width="100%" justifyContent="center" alignItems="center">
              <Flex flexDirection="column">
                <Flex width="100%" justifyContent="center" flexDirection="column">
                  <PoweredBy />
                  <Flex
                    mt="m"
                    flexDirection={{ mobile: 'row', desktop: 'column' }}
                    flexWrap="wrap"
                    justifyContent="center"
                  >
                    <H1
                      fontSize="xxxl"
                      fontWeight="bold"
                      color={COLOR_CONSTANTS.DENIM}
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      dangerouslySetInnerHTML={{
                        __html: changeTextColorByMatch({
                          text: headerText,
                          mainColor: COLOR_CONSTANTS.DENIM,
                          changeColor: colors.primary,
                          matches: [network],
                        }),
                      }}
                    />
                  </Flex>
                  <H2
                    mt="m"
                    pt="s"
                    color="secondaryText"
                    fontSize="m"
                    textAlign={{ mobile: 'center', desktop: 'left' }}
                    fontWeight="normal"
                    lineHeight="xl"
                  >
                    {description}
                  </H2>
                  <TrialTerms />
                  <SelectPlanButton />
                  <Awards />
                </Flex>
              </Flex>
            </Flex>
            <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }}>
              <LocalImage src={image} width="100%" maxWidth="673px" mx="auto" alt={headerText} />
            </Flex>
          </Grid>
        </Flex>
      </Container>

      <Container mt="xl" pb="s" pl="m" pr="m" justifyContent="center" maxWidth="1260px" width="100%" height="100%">
        <Flex my="m" width="100%" justifyContent="center" flexWrap="wrap">
          {INTEGRATION_TABS.map(({ pagePath, name }) => {
            const isActive = name === network
            return (
              <StyledCategories href={pagePath} key={pagePath} isActive={isActive}>
                <Text fontSize="xxs" color={isActive ? 'white' : 'primary'}>
                  {name}
                </Text>
              </StyledCategories>
            )
          })}
        </Flex>
      </Container>
    </Wrapper>
  )
}

PageHeader.propTypes = {
  network: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
}

export default PageHeader
